import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { isBrowser } from "../../../context/ApolloContext"
const $ = isBrowser ? require("jquery") : {}
import { useLocation } from "@reach/router"
import CoqIcon from "../../../../static/ChristmasFlake.svg"

const ChristmasStickyBannerWrapper = styled.div`
  width: 100%;
  padding: 8px 20px;
  font-size: 18px;
  text-align: center;
  background: ${props => props.theme.saleColor.bgColor};
  color: ${props => props.theme.saleColor.textColor};
  position: relative;
  overflow: hidden;
  min-height: 53px;
  display: flex;

  @media (max-width: 720px) {
    min-height: 50px;
  }

  @media (max-width: 480px) {
    min-height: 44px;
  }

  @media (max-width: 450px) {
    padding: 8px 10px;
  }

  @media (max-width: 380px) {
    min-height: 40px;
  }
`

const LinkBox = styled(Link)`
  width: 100%;
  position: absolute;
  height: 100%;
  z-index: 1;
  display: none;
  display: block;

  @media (max-width: 767px) {
    display: block;
  }
`

const LinkButtonTitle = styled.span`
  display: none;
`

const Container = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 0px auto;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const TextBox = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Museo";
  font-weight: 500;
  font-size: 21px;
  line-height: 1.2;
  color: ${props => props.theme.saleColor.textColor};
  text-align: left;
`

const LeftText = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Museo";
  font-weight: 900;
  font-size: 18px;
  line-height: 1;
  // background: ${props => props.theme.saleColor.bgColor};
  color: ${props => props.theme.saleColor.textColor};
  // background: ${props => props.theme.saleColor.textColor};
  // color: ${props => props.theme.saleColor.bgColor};
  // padding: 5px 10px 3px;
  // border: ${props => "1px solid" + props.theme.saleColor.borderColor};
  // flex-direction: column;

  @media (max-width: 720px) {
    font-size: 16px;
  }

  @media (max-width: 660px) {
    font-size: 14px;
  }

  @media (max-width: 600px) {
    display: none;
  }
`

const LeftTextBox = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Museo";
  font-weight: 900;
  font-size: 21px;
  line-height: 1;
  text-transform: uppercase;

  span {
    font-size: 10px;
    font-family: "Museo";
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: none;
    // background: ${props => props.theme.saleColor.textColor};
    // color: ${props => props.theme.saleColor.bgColor};
    color: ${props => props.theme.saleColor.textColor};

    @media (max-width: 420px) {
      // letter-spacing: 1px;
    }
  }

  @media (max-width: 720px) {
    // font-size: 16px;
  }

  // @media (max-width: 660px) {
  //   font-size: 14px;
  // }

  // @media (max-width: 600px) {
  //   font-size: 18px;
  //   flex-direction: column;
  // }

  @media (max-width: 480px) {
    // font-size: 14px;
  }

  @media (max-width: 400px) {
    // font-size: 13px;
  }
`

const MobileLeftText = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Museo";
  font-weight: 900;
  font-size: 18px;
  line-height: 1;
  // background: ${props => props.theme.saleColor.bgColor};
  color: ${props => props.theme.saleColor.textColor};
  // background: ${props => props.theme.saleColor.textColor};
  // color: ${props => props.theme.saleColor.bgColor};
  // padding: 5px 10px 3px;
  // border: ${props => "1px solid" + props.theme.saleColor.borderColor};
  // flex-direction: column;

  @media (max-width: 600px) {
    display: inline-flex;
  }

  @media (max-width: 480px) {
    // font-size: 18px;
    // padding: 5px 6px;
  }

  @media (max-width: 420px) {
    font-size: 14px;
    // padding: 5px 7px 3px;
    // max-width: 85px;
  }
`

const RightText = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Museo";
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  margin-left: ${({ marginLeft }) => (marginLeft ? "7px" : "0px")};
  color: ${props => props.theme.saleColor.textColor};

  > span:nth-of-type(1) {
    margin: 0px 5px;
    font-size: 21px;
    font-family: "Museo";
    font-weight: 900;
    // padding: 7px 8px 6px;
    // background: ${props => props.theme.colors.white.base};
    color: ${props => props.theme.saleColor.textColor};
    // background: ${props => props.theme.saleColor.textColor};
    // color: ${props => props.theme.saleColor.bgColor};

    @media (max-width: 720px) {
      font-size: 21px;
    }

    @media (max-width: 480px) {
      font-size: 21px;
      // padding: 5px 6px;
    }

    @media (max-width: 420px) {
      // font-size: 16px;
    }

    @media (max-width: 380px) {
      // font-size: 14px;
    }
  }

  span > span {
    @media (max-width: 600px) {
      display: block;
    }
  }

  sup {
    // margin-right: 4px;
  }

  @media (max-width: 720px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }

  @media (max-width: 380px) {
    font-size: 12px;
  }
`

const SecondRightText = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Museo";
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  margin-left: ${({ marginLeft }) => (marginLeft ? "7px" : "0px")};
  color: ${props => props.theme.saleColor.textColor};

  span > span {
    @media (max-width: 600px) {
      display: block;
    }
  }

  sup {
    // margin-right: 4px;
  }

  @media (max-width: 720px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }

  @media (max-width: 380px) {
    font-size: 12px;
  }
`

const OreillerRightText = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-family: "Museo";
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
`

const OreillerTextBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

const OreillerTextBox = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Museo";
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: ${props => props.theme.saleColor.textColor};

  > span:nth-of-type(1) {
    margin: 0px 5px;
    font-size: 21px;
    font-family: "Museo";
    font-weight: 900;
    // padding: 7px 8px 6px;
    // background: ${props => props.theme.colors.white.base};
    color: ${props => props.theme.saleColor.textColor};
    // background: ${props => props.theme.saleColor.textColor};
    // color: ${props => props.theme.saleColor.bgColor};

    @media (max-width: 850px) {
      // font-size: 18px;
      margin: 0px 5px;
      // padding: 4px 2px 3px;
    }

    @media (max-width: 480px) {
      // font-size: 18px;
    }
  }

  @media (max-width: 720px) {
    font-size: 14px;
  }

  @media (max-width: 600px) {
    margin: 3px 0px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }

  @media (max-width: 350px) {
    font-size: 12px;
  }
`

const Button = styled(Link)`
  color: ${props => props.theme.saleColor.textColor};
  font-size: 16px;
  font-family: "Museo";
  font-weight: 500;
  margin-left: 25px;
  text-decoration: underline;
  line-height: 22px;
  display: inline-block;

  @media (max-width: 600px) {
    display: none;
  }

  :hover {
    color: ${props => props.theme.saleColor.textColor};
  }

  @media (max-width: 720px) {
    font-size: 14px;
  }
`

const MobileButton = styled(Link)`
  display: none;
  position: relative;
  margin-left: 10px;
  color: ${props => props.theme.saleColor.textColor};
  font-weight: bold;

  :hover {
    color: ${props => props.theme.saleColor.textColor};
  }

  @media (max-width: 600px) {
    display: inline-block;
  }

  @media (max-width: 420px) {
    margin-left: 5px;
  }
`

const ClockImage = styled.img`
  width: 16px;
  max-width: 16px;
  margin: 0px 0px 3px 5px;
`

const ChristmasStickyBanner = ({ intl }) => {
  const [activeFlag, setactiveFlag] = useState(true)
  const [shopButtonHide, setShopButtonHide] = useState(false)
  const [isLynePage, setisLynePage] = useState(false)
  const [isOreiller, setisOreiller] = useState(false)
  const [isMatelas, setisMatelas] = useState(false)
  const [isSurmatelas, setisSurmatelas] = useState(false)
  const [isSommierPage, setisSommierPage] = useState(false)
  const [isChaisePage, setisChaisePage] = useState(false)

  const { pathname } = useLocation()

  const checkButtonVisibility = () => {
    let currentPath = ""
    let ButtonHide = false

    let regex = /(lyneup|lynefit|lynehome|matelas|surmatelas|oreiller|sommiers-zones|sommier-zone-original|sommier-zone-coffre|sommier-zone-bois-massif|lit-electrique|oreillers-ergonomiques|chaise-bureau-ergonomique|shop|panier|cart|dispositif-medical|scientific-study)/
    let lyneRegex = /(lyneup|lynefit|lynehome|wearable-technology|solutions-textiles)/
    let oreillerRegex = /(oreiller|oreillers-ergonomiques)/
    let matelasRegex = /(matelas)/
    let surMatelasRegex = /(surmatelas)/
    let sommierRegex = /(sommiers-zones|sommier-zone-original|sommier-zone-coffre|sommier-zone-bois-massif|lit-electrique)/
    let chaiseRegex = /(chaise-bureau-ergonomique)/

    currentPath = window.location.pathname

    ButtonHide = regex.test(currentPath)
    setShopButtonHide(ButtonHide)
    setisLynePage(lyneRegex.test(currentPath))
    setisOreiller(oreillerRegex.test(currentPath))
    setisMatelas(matelasRegex.test(currentPath))
    setisSurmatelas(surMatelasRegex.test(currentPath))
    setisSommierPage(sommierRegex.test(currentPath))
    setisChaisePage(chaiseRegex.test(currentPath))
  }

  const BottomBarActive = () => {
    function isOnScreen(elem) {
      // if the element doesn't exist, abort
      if (elem.length == 0) {
        return
      }
      var $window = $(window)
      var viewport_top = $window.scrollTop()
      var viewport_height = $window.height()
      var viewport_bottom = viewport_top + viewport_height
      var $elem = $(elem)
      var top = $elem.offset().top
      var height = $elem.height()
      var bottom = top + height

      return (
        (top >= viewport_top && top < viewport_bottom) ||
        (bottom > viewport_top && bottom <= viewport_bottom) ||
        (height > viewport_height &&
          top <= viewport_top &&
          bottom >= viewport_bottom)
      )
    }
    $(window).resize(() => {
      var w = window.outerWidth
      if (w > 600) {
        if (isOnScreen($(".HomeVideoBanner"))) {
          /* Pass element id/class you want to check */
          setactiveFlag(false)
        } else {
          setactiveFlag(true)
        }
        window.addEventListener("scroll", function(e) {
          if (isOnScreen($(".HomeVideoBanner"))) {
            /* Pass element id/class you want to check */
            setactiveFlag(false)
          } else {
            setactiveFlag(true)
          }
        })
      }
    })

    $(document).ready(function() {
      var w = window.outerWidth
      if (w > 600) {
        if (isOnScreen($(".HomeVideoBanner"))) {
          /* Pass element id/class you want to check */
          setactiveFlag(false)
        } else {
          setactiveFlag(true)
        }
        window.addEventListener("scroll", function(e) {
          if (isOnScreen($(".HomeVideoBanner"))) {
            /* Pass element id/class you want to check */
            setactiveFlag(false)
          } else {
            setactiveFlag(true)
          }
        })
      }
    })
  }

  useEffect(() => {
    // BottomBarActive()
  }, [intl.locale])

  useEffect(() => {
    isBrowser && checkButtonVisibility()
  }, [pathname])
  return (
    activeFlag && (
      <ChristmasStickyBannerWrapper className="LyneHomeStickyBanner">
        {shopButtonHide ? (
          <Container>
            {isLynePage ? (
              <TextBox>
                <LeftText>
                  <LeftTextBox>
                    SOLDES
                    <span>Derniers jours</span>
                  </LeftTextBox>
                  {/* <ClockImage src={CoqIcon} style={{ maxWidth: 16 }} /> */}
                </LeftText>
                <MobileLeftText>
                  <LeftTextBox>
                    SOLDES
                    <span>Derniers jours</span>
                  </LeftTextBox>
                  {/* <ClockImage src={CoqIcon} style={{ maxWidth: 16 }} /> */}
                </MobileLeftText>
                <RightText>
                  <span>-50%</span>
                  <span>
                    sur le 2<sup>e</sup> produit
                  </span>
                </RightText>
              </TextBox>
            ) : isOreiller ? (
              <TextBox>
                <LeftText>
                  <LeftTextBox>
                    SOLDES
                    <span>Derniers jours</span>
                  </LeftTextBox>
                  {/* <ClockImage src={CoqIcon} style={{ maxWidth: 16 }} /> */}
                </LeftText>
                <MobileLeftText>
                  <LeftTextBox>
                    SOLDES
                    <span>Derniers jours</span>
                  </LeftTextBox>
                  {/* <ClockImage src={CoqIcon} style={{ maxWidth: 16 }} /> */}
                </MobileLeftText>
                <OreillerRightText>
                  <OreillerTextBoxWrapper>
                    <OreillerTextBox>
                      <span>-25%</span>
                      dès 2 oreillers
                    </OreillerTextBox>
                    <OreillerTextBox>
                      <span>-30%</span>
                      dès 3 oreillers
                    </OreillerTextBox>
                  </OreillerTextBoxWrapper>
                </OreillerRightText>
              </TextBox>
            ) : isSurmatelas ? (
              <TextBox>
                <LeftText>
                  <LeftTextBox>
                    SOLDES
                    <span>Derniers jours</span>
                  </LeftTextBox>
                  {/* <ClockImage src={CoqIcon} style={{ maxWidth: 16 }} /> */}
                </LeftText>
                <MobileLeftText>
                  <LeftTextBox>
                    SOLDES
                    <span>Derniers jours</span>
                  </LeftTextBox>
                  {/* <ClockImage src={CoqIcon} style={{ maxWidth: 16 }} /> */}
                </MobileLeftText>
                <RightText>
                  <span>-15%</span>
                  <span>
                    sur le <span>surmatelas</span>
                  </span>
                </RightText>
                {/* <SecondRightText marginLeft="5px">
                  <span>
                    Jusqu'à 100€ offerts{" "}
                    <span>pour l'achat d'un surmatelas</span>
                  </span>
                </SecondRightText> */}
              </TextBox>
            ) : isMatelas ? (
              <TextBox>
                <LeftText>
                  <LeftTextBox>
                    SOLDES
                    <span>Derniers jours</span>
                  </LeftTextBox>
                  {/* <ClockImage src={CoqIcon} style={{ maxWidth: 16 }} /> */}
                </LeftText>
                <MobileLeftText>
                  <LeftTextBox>
                    SOLDES
                    <span>Derniers jours</span>
                  </LeftTextBox>
                  {/* <ClockImage src={CoqIcon} style={{ maxWidth: 16 }} /> */}
                </MobileLeftText>
                <RightText>
                  <span>-30%</span>
                  <span>
                    sur le pack <span>matelas + oreiller</span>
                  </span>
                </RightText>
                {/* <SecondRightText marginLeft="5px">
                  <span>
                    Jusqu’à 200€ offerts <span>pour vos achats</span>
                  </span>
                </SecondRightText> */}
              </TextBox>
            ) : isSommierPage ? (
              <TextBox>
                <LeftText>
                  <LeftTextBox>
                    SOLDES
                    <span>Derniers jours</span>
                  </LeftTextBox>
                  {/* <ClockImage src={CoqIcon} style={{ maxWidth: 16 }} /> */}
                </LeftText>
                <MobileLeftText>
                  <LeftTextBox>
                    SOLDES
                    <span>Derniers jours</span>
                  </LeftTextBox>
                  {/* <ClockImage src={CoqIcon} style={{ maxWidth: 16 }} /> */}
                </MobileLeftText>
                <RightText>
                  <span>-30%</span>
                  <span>
                    sur le pack <span>sommier + matelas</span>
                  </span>
                </RightText>
              </TextBox>
            ) : isChaisePage ? (
              <TextBox>
                <LeftText>
                  <LeftTextBox>
                    SOLDES
                    <span>Derniers jours</span>
                  </LeftTextBox>
                  {/* <ClockImage src={CoqIcon} style={{ maxWidth: 16 }} /> */}
                </LeftText>
                <MobileLeftText>
                  <LeftTextBox>
                    SOLDES
                    <span>Derniers jours</span>
                  </LeftTextBox>
                  {/* <ClockImage src={CoqIcon} style={{ maxWidth: 16 }} /> */}
                </MobileLeftText>
                <RightText>
                  <span>-100€</span>
                  <span>sur la chaise</span>
                </RightText>
              </TextBox>
            ) : (
              <TextBox>
                <LeftText>
                  <LeftTextBox>
                    SOLDES
                    <span>Derniers jours</span>
                  </LeftTextBox>
                  {/* <ClockImage src={CoqIcon} style={{ maxWidth: 16 }} /> */}
                </LeftText>
                <MobileLeftText>
                  <LeftTextBox>
                    SOLDES
                    <span>Derniers jours</span>
                  </LeftTextBox>
                  {/* <ClockImage src={CoqIcon} style={{ maxWidth: 16 }} /> */}
                </MobileLeftText>
                <RightText marginLeft="5px">
                  Jusqu’à
                  <span>-50%</span>
                  <span>
                    sur le 2<sup>e</sup> <span>produit</span>
                  </span>
                </RightText>
              </TextBox>
            )}
          </Container>
        ) : (
          <Container>
            <LinkBox to="/shop/offres/">
              <LinkButtonTitle>Je découvre</LinkButtonTitle>
            </LinkBox>
            <TextBox>
              <LeftText>
                <LeftTextBox>
                  SOLDES
                  <span>Derniers jours</span>
                </LeftTextBox>
                {/* <ClockImage src={CoqIcon} style={{ maxWidth: 16 }} /> */}
              </LeftText>
              <MobileLeftText>
                <LeftTextBox>
                  SOLDES
                  <span>Derniers jours</span>
                </LeftTextBox>
                {/* <ClockImage src={CoqIcon} style={{ maxWidth: 16 }} /> */}
              </MobileLeftText>
              <RightText marginLeft="5px">
                Jusqu’à
                <span>-50%</span>
                <span>
                  sur le 2<sup>e</sup> <span>produit</span>
                </span>
              </RightText>
            </TextBox>
            <Button to="/shop/offres/">J'en profite {">"}</Button>
            <MobileButton to="/shop/offres/">{">"}</MobileButton>
          </Container>
        )}
      </ChristmasStickyBannerWrapper>
    )
  )
}

export default injectIntl(ChristmasStickyBanner)
